// MUST BE SYNCED WITH BACKEND
// remonline/auth/permissions.py

const PERMISSIONS_USER = {
  PERMISSION_PARTNER: 'BI',

  PERMISSION_COMMON: 'CA',
  PERMISSION_COMMON_MULTIUSER: 'A',
  PERMISSION_CAN_MAKE_PAYMENT_DEBT: 'YF',
  PERMISSION_COMMON_INCOME_PRICE: 'B',
  PERMISSION_COMMON_DISCOUNT_EDIT: 'C',
  PERMISSION_COMMON_COST_EDIT: 'CY',
  PERMISSION_CAN_SEE_ESTIMATED_PROFIT: 'YE',
  PERMISSION_COMMON_SHOW_IN_MANAGERS_LIST: 'D',
  PERMISSION_COMMON_SHOW_IN_ENGINEERS_LIST: 'E',
  PERMISSION_COMMON_CAN_SEND_SMS: 'Y',
  PERMISSION_COMMON_DISCOUNT_SPONSOR: 'DE',
  PERMISSION_COMMON_CAN_SEE_ASSET_COST: 'EZ',
  PERMISSION_CAN_SET_EXCEPTIONAL_COMMISSION: 'HT',
  PERMISSION_CAN_SEE_OWN_PAYROLL: 'HD',

  PERMISSION_DASHBOARD: 'DS',
  PERMISSION_ANALYTICS: 'ER',
  PERMISSION_ANALYTIC_REPORT: 'FP',
  PERMISSION_ASSORTMENT_ANALYSIS: 'EQ',

  // Inactive.
  // PERMISSION_COMPANY: 'F'
  PERMISSION_COMPANY_BRANCHES: 'G',
  PERMISSION_COMPANY_SETTINGS: 'K',
  PERMISSION_COMPANY_LICENSE: 'L',
  PERMISSION_COMPANY_STATUSES: 'M',
  PERMISSION_SETTINGS_TAGS: 'EG',
  PERMISSION_COMPANY_REFERRALS: 'N',
  PERMISSION_COMPANY_PRICES: 'O',

  PERMISSION_EMPLOYEES: 'H',
  PERMISSION_EMPLOYEES_CAN_VIEW: 'HM',
  PERMISSION_EMPLOYEES_CAN_INVITE: 'HN',
  PERMISSION_EMPLOYEES_CAN_ADD: 'HO',
  PERMISSION_EMPLOYEES_CAN_EDIT: 'FZ',
  PERMISSION_EMPLOYEES_CAN_EDIT_GENERAL: 'GG',
  PERMISSION_EMPLOYEES_CAN_EDIT_ACCESS: 'GH',
  PERMISSION_EMPLOYEES_CAN_EDIT_BALANCE: 'GI',
  PERMISSION_EMPLOYEES_CAN_VIEW_BALANCE: 'GJ',
  PERMISSION_EMPLOYEES_CAN_ADJUST_BALANCE: 'GK',
  PERMISSION_EMPLOYEES_CAN_VIEW_PAYROLL_RULES: 'GL',
  PERMISSION_EMPLOYEES_CAN_VIEW_COMPENSATION: 'GO',
  PERMISSION_EMPLOYEES_CAN_EDIT_OWN_PROFILE: 'GP',
  PERMISSION_EMPLOYEES_CAN_DELETE: 'GN',

  PERMISSION_ROLES: 'I',
  PERMISSION_ROLES_CAN_VIEW: 'GM',
  PERMISSION_ROLES_CAN_CREATE: 'GQ',
  PERMISSION_ROLES_CAN_SET_UP: 'GR',
  PERMISSION_ROLES_CAN_DELETE: 'GS',

  PERMISSION_PAYROLL_CALCULATION_RULES: 'GT',
  PERMISSION_PAYROLL_CAN_VIEW_RULES: 'HF',
  PERMISSION_PAYROLL_CAN_CREATE_RULES: 'HG',
  PERMISSION_PAYROLL_CAN_EDIT_RULES: 'HH',
  PERMISSION_PAYROLL_CAN_APPLY_RULES_TO_EMPLOYEES: 'HI',
  PERMISSION_PAYROLL_CAN_DELETE_RULES: 'HJ',

  PERMISSION_ORDERS: 'P',
  PERMISSION_ORDERS_CREATE: 'Q',
  PERMISSION_ESTIMATES_CREATE: 'HV',
  PERMISSION_ORDERS_UPDATE_INFO: 'R',
  PERMISSION_ORDERS_UPDATE_WORK_MATERIALS: 'CI',
  PERMISSION_ORDERS_DELETE: 'S',
  PERMISSION_ORDERS_MOVE: 'T',
  PERMISSION_ORDERS_REOPEN: 'U',
  PERMISSION_ORDERS_CUSTOM_PART: 'W',
  PERMISSION_ORDERS_CUSTOM_OPERATION: 'CZ',
  PERMISSION_ORDERS_WAREHOUSE_PART: 'X',
  PERMISSION_ORDERS_CAN_SEE_CLIENT: 'Z',
  PERMISSION_ORDERS_EDIT_MANAGER_ENGINEER: 'CH',
  PERMISSION_ORDERS_EDIT_SERVICES_PRICE: 'DI',
  PERMISSION_ORDERS_EDIT_MATERIALS_PRICE: 'DJ',
  PERMISSION_ORDERS_CUSTOM_EDIT_SERVICES_PRICE: 'HK',
  PERMISSION_ORDERS_ADD_EXISTING_OPERATION: 'VC',
  PERMISSION_ORDERS_CAN_PRINT_FISCAL_TWICE: 'PF',
  PERMISSION_ORDERS_CAN_MANAGE_SHARED_FILTER: 'EE',
  PERMISSION_ORDERS_CAN_SEE_CALLS: 'ET',
  PERMISSION_ORDERS_CAN_PRINT: 'HR',
  PERMISSION_ORDERS_CAN_CREATE_ASSET_FROM_ORDER: 'FG',
  PERMISSION_ORDERS_CAN_SEE_ASSET: 'FH',
  PERMISSION_ORDERS_CAN_CREATE_ELEMENTS_OF_ASSETS_BOOK: 'FO',
  PERMISSION_ORDERS_CAN_SEE_PRICES_OF_LABORS_SERVICES_PRODUCTS: 'IB',
  PERMISSION_ORDERS_CAN_SEE_INVOICES_AND_PAYMENTS_TAB: 'IC',
  PERMISSION_ORDERS_CAN_ADD_PRODUCTS_WITHOUT_DEDUCTION: 'IJ',
  PERMISSION_ORDERS_CAN_CREATE_PAYMENT_LINK: 'IS',

  PERMISSIONS_FINANCE: 'GA',
  PERMISSIONS_FINANCE_SETTINGS_ROUNDING: 'IW',

  PERMISSION_INVOICES: 'CV',

  PERMISSION_PAYROLL_CALCULATION: 'GU',

  PERMISSION_PAYROLL_ACCRUALS: 'GV',
  PERMISSION_PAYROLL_ACCRUALS_CAN_VIEW: 'GW',
  PERMISSION_PAYROLL_ACCRUALS_CAN_CREATE: 'GX',
  PERMISSION_PAYROLL_ACCRUALS_CAN_ACCRUE: 'GY',
  PERMISSION_PAYROLL_ACCRUALS_CAN_CREATE_PENALTIES: 'GZ',
  PERMISSION_PAYROLL_ACCRUALS_CAN_CREATE_BONUSES: 'HA',
  PERMISSION_PAYROLL_ACCRUALS_CAN_PRINT: 'HC',
  PERMISSION_PAYROLL_ACCRUALS_CAN_DELETE: 'HB',
  PERMISSION_PAYROLL_CALCULATION_PRINT: 'HY',
  PERMISSION_PAYROLL_CALCULATION_EXPORT: 'HW',
  PERMISSION_REPORTS_PAYROLL_EXPORT: 'HX',

  PERMISSION_BALANCE: 'GB',
  PERMISSION_BALANCE_CAN_SEE: 'GC',
  PERMISSION_BALANCE_CAN_EXPORT: 'GD',

  PERMISSION_CASHBOX: 'AA',
  PERMISSION_CASHBOX_INCOME: 'AB',
  PERMISSION_CASHBOX_OUTCOME: 'AC',
  PERMISSION_CASHBOX_CASHFLOW: 'AD',
  PERMISSION_CASHBOX_DEPOSIT: 'DF',
  PERMISSION_CASHBOX_CAN_EDIT: 'AE',
  PERMISSION_CASHBOX_DELETE_OPERATION: 'AF',
  PERMISSION_CASHBOX_CAN_MOVE: 'CK',
  PERMISSION_CASHBOX_CAN_MOVE_BY_CUSTOM_DATE: 'DH',
  PERMISSION_CASHBOX_CAN_CREATE_CORRECTION: 'FS',
  PERMISSION_CASHBOX_PRINT_CASHFLOW: 'PC',
  PERMISSION_CASHBOX_EXPORT_CASHFLOW: 'EC',
  PERMISSION_CASHBOX_CAN_CHOOSE_DATE: 'DR',

  PERMISSION_SHOP: 'AG',
  PERMISSION_SHOP_DELETE_OPERATION: 'AH',
  PERMISSION_SHOP_CAN_SELL: 'AI',
  PERMISSION_SHOP_CAN_SELL_SERVICES: 'EJ',
  PERMISSION_SHOP_EDIT_PRICE: 'CJ',
  PERMISSION_SHOP_EXPORT_SALES: 'SE',

  PERMISSION_REFUNDS: 'DB',
  PERMISSION_REFUNDS_CREATE: 'DC',
  PERMISSION_REFUNDS_DELETE: 'DD',
  PERMISSION_REFUNDS_EDIT_PRICE: 'CX',

  PERMISSION_FINANCE_PAYMENT_LINK: 'IV',
  PERMISSION_FINANCE_CAN_CREATE_PAYMENT_LINK: 'IU',
  PERMISSION_FINANCE_CAN_DELETE_PAYMENT_LINK: 'IT',

  PERMISSION_SUPPLIER_REFUNDS: 'EA',
  PERMISSION_SUPPLIER_REFUNDS_VIEW: 'ED',
  PERMISSION_SUPPLIER_REFUNDS_CREATE: 'EB',
  PERMISSION_SUPPLIER_REFUNDS_DELETE: 'IQ',

  PERMISSION_WAREHOUSE: 'AJ',
  PERMISSION_WAREHOUSE_RESIDUE: 'AK',
  PERMISSION_WAREHOUSE_INCOME: 'AL',
  PERMISSION_WAREHOUSE_VIEW_INCOME: 'EO',
  PERMISSION_WAREHOUSE_CREATE_INCOME: 'EM',
  PERMISSION_WAREHOUSE_EDIT_INCOME: 'IE',
  PERMISSION_WAREHOUSE_DELETE_INCOME: 'EN',
  PERMISSION_WAREHOUSE_OUTCOME: 'AM',
  PERMISSION_WAREHOUSE_VIEW_OUTCOME: 'IF',
  PERMISSION_WAREHOUSE_CREATE_OUTCOME: 'IG',
  PERMISSION_WAREHOUSE_DELETE_OUTCOME: 'IH',
  PERMISSION_WAREHOUSE_EDIT_OUTCOME: 'II',
  PERMISSION_WAREHOUSE_MOVE: 'AN',
  PERMISSION_WAREHOUSE_CATEGORIES: 'AO',
  PERMISSION_WAREHOUSE_CAN_EDIT_CATEGORY: 'EU',
  PERMISSION_WAREHOUSE_DELETE_OPERATION: 'AP',
  PERMISSION_WAREHOUSE_DELETE_PRODUCT: 'KP',
  PERMISSION_WAREHOUSE_EXPORT: 'WE',
  PERMISSION_WAREHOUSE_IMPORT: 'WI',
  PERMISSION_WAREHOUSE_SN_ACCOUNTING_TURN_ON: 'DU',
  PERMISSION_STOCKTAKING: 'DZ',
  PERMISSION_STOCKTAKING_VIEW: 'DW',
  PERMISSION_STOCKTAKING_CREATE: 'DY',
  PERMISSION_STOCKTAKING_DELETE: 'EI',
  PERMISSION_STOCKTAKING_SUMMARY_VIEW: 'EH',
  PERMISSION_SUPPLIER_REFUNDS_EDIT: 'IR',
  PERMISSION_WAREHOUSE_CONVERT_PRODUCT_TO_ASSET: 'CD',

  PERMISSION_CLIENTS: 'AQ',
  PERMISSION_SEE_CLIENTS_TABLE: 'EL',
  PERMISSION_CLIENTS_EXPORT: 'DX',
  PERMISSION_CLIENTS_IMPORT: 'ZZ',
  PERMISSION_SEE_CLIENT_BALANCE: 'GE',
  PERMISSION_EDIT_CLIENT_BALANCE: 'GF',
  PERMISSION_EDIT_CLIENTS: 'CO',
  PERMISSION_CAN_DELETE_CLIENT: 'EF',
  PERMISSION_EDIT_CLIENTS_DISCOUNT: 'DA',
  PERMISSION_CREATE_ENTITIES: 'EP',
  PERMISSION_EDIT_ENTITIES: 'CP',
  PERMISSION_SEE_CLIENTS: 'CQ',
  PERMISSION_SEE_SUPPLIERS: 'CR',
  PERMISSION_CLIENTS_CAN_SEE_CALLS: 'ES',

  PERMISSION_TELEPHONY: 'CT',

  PERMISSION_REPORTS: 'AR',
  PERMISSION_REPORTS_FINANCE_EMPLOYEES: 'AS',
  PERMISSION_REPORTS_FINANCE_CASHFLOW: 'AT',
  PERMISSION_REPORTS_FINANCE_ORDERS_PROFIT: 'AU',
  PERMISSION_REPORTS_FINANCE_SHOP_PROFIT: 'AV',
  PERMISSION_REPORTS_FINANCE_TAXES_REPORT: 'IA',
  PERMISSION_REPORTS_ORDERS_INCOME: 'AW',
  PERMISSION_REPORTS_ORDERS_OUTCOME: 'AX',
  PERMISSION_REPORTS_ORDERS_CURRENT: 'AY',
  PERMISSION_REPORTS_ORDERS_SERVICES: 'DP',
  PERMISSION_REPORTS_ORDERS_ENGINEERS: 'DQ',
  PERMISSION_REPORTS_LEADS_ALL: 'LA',
  PERMISSION_REPORTS_LEAD_CONVERSION: 'LC',
  PERMISSION_REPORTS_LEADS_EMPLOYEE_PERFORMANCE: 'LE',
  PERMISSION_REPORTS_LEADS_LOST: 'LL',
  PERMISSION_REPORTS_WAREHOUSE_RESIDUE_SIMPLE: 'AZ',
  PERMISSION_REPORTS_WAREHOUSE_RESIDUE: 'BA',
  PERMISSION_REPORTS_WAREHOUSE_OUTCOME: 'BB',
  PERMISSION_REPORTS_WAREHOUSE_REQUIRING_PURCHASE: 'CN',
  PERMISSION_REPORTS_WAREHOUSE_GOODS_FLOW: 'DK',
  PERMISSION_REPORTS_SMS: 'BC',
  PERMISSION_REPORTS_DEFERRED_SMS: 'DT',
  PERMISSION_REPORTS_AD_CAMPAIGNS: 'CM',
  PERMISSION_REPORTS_REVIEW: 'CU',
  PERMISSION_REPORTS_TOTAL_CASH: 'DN',
  PERMISSION_REPORTS_EXPORT: 'RE',
  PERMISSION_REPORTS_PRINT: 'RP',
  PERMISSION_REPORTS_CAN_CHOOSE_DATE: 'RD',
  PERMISSION_REPORTS_EVENTS_LOG: 'FR',

  PERMISSION_SETTINGS: 'BD',
  PERMISSION_SETTINGS_WAREHOUSES: 'WC',
  PERMISSION_SETTINGS_FINANCE: 'IP',
  PERMISSION_SETTINGS_TAXES: 'TA',
  PERMISSION_SETTINGS_TAXES_VIEW: 'TV',
  PERMISSION_SETTINGS_TAXES_CAN_ADD_RULE: 'TB',
  PERMISSION_SETTINGS_TAXES_SET_GLOBAL: 'TG',
  PERMISSION_SETTINGS_TAXES_ADD_EXCEPTION: 'TX',
  PERMISSION_SETTINGS_TAXES_EDIT_IN_DOCUMENTS: 'TY',
  PERMISSION_SETTINGS_SERVICES_PRICELIST: 'DO',
  PERMISSION_SETTINGS_SERVICES_VIEW: 'CW',
  PERMISSION_SETTINGS_SERVICES_CREATE: 'HL',
  PERMISSION_SETTINGS_SERVICES_EDIT: 'HP',
  PERMISSION_SETTINGS_SERVICES_DELETE: 'HQ',
  PERMISSION_SETTINGS_INTEGRATIONS: 'CS',
  PERMISSION_SETTINGS_DIRECTORIES: 'BE',
  PERMISSION_SETTINGS_MARKETING: 'DV',
  PERMISSION_SETTINGS_DOCUMENT_TEMPLATES: 'BF',
  PERMISSION_SETTINGS_PUBLIC_PAGE: 'IX',
  PERMISSION_SETTINGS_FORM: 'BG',
  PERMISSION_SETTINGS_NOTIFICATIONS: 'BH',
  PERMISSION_SETTINGS_API: 'CC',

  PERMISSION_SCHEDULE: 'FT',
  PERMISSION_SCHEDULE_CAN_SEE: 'FV',
  PERMISSION_SCHEDULE_CAN_CREATE_UPDATE: 'FU',

  PERMISSION_TASKS: 'DG',
  PERMISSION_TASKS_CAN_BE_PERFORMER: 'TP',
  PERMISSION_TASKS_CAN_CREATE: 'TC',
  PERMISSION_TASKS_CAN_SEE_TASKS_ALL_EMPLOYEES: 'TS',
  PERMISSION_TASKS_CAN_EDIT_TASKS_ALL_EMPLOYEES: 'TE',

  PERMISSION_LEADS: 'EX',
  PERMISSION_LEADS_VIEW: 'FQ',
  PERMISSION_LEADS_CREATE: 'EY',
  PERMISSION_LEADS_CHANGE_STATUS: 'FY',
  PERMISSION_LEADS_UPDATE: 'EV',
  PERMISSION_LEADS_DELETE: 'EW',

  PERMISSION_ASSETS: 'FJ',
  PERMISSION_ASSETS_CREATE: 'FK',
  PERMISSION_ASSETS_UPDATE: 'FL',
  PERMISSION_ASSETS_MAKE_OUTCOME: 'FM',
  PERMISSION_ASSETS_MAKE_MOVE: 'FN',
  PERMISSION_ASSETS_MERGE: 'MA',

  PERMISSION_BUNDLES: 'IK',
  PERMISSION_BUNDLES_VIEW: 'IL',
  PERMISSION_BUNDLES_CREATE: 'IN',
  PERMISSION_BUNDLES_UPDATE: 'IO',
  PERMISSION_BUNDLES_DELETE: 'IM',

  PERMISSION_STAFF_CHAT_ALL: 'KA',
  PERMISSION_STAFF_CHAT: 'KB',
  PERMISSION_STAFF_CAN_CREATE_EDIT_CHANNELS_CHAT: 'KC',
  PERMISSION_STAFF_CAN_CREATE_EDIT_GET_CHANNELS_OF_OTHER_EMPLOYEE: 'KD',
} as const;

export default PERMISSIONS_USER;
