###
    Auxiliary functions to manipulate and control DOM tree.
###

{keys, bool, KEY} = require "lib/helpers"
{toHash, any} = require "lib/fp"
{isMobile} = require "lib/mobile"

{fromEvent} = require "rxjs/observable/fromEvent"


# Wraps given element into jQuery API.
# -> object element: target element.
# Returns IDom: DOM element.
jwrap = (element) ->
    if (element instanceof jQuery) then element else jQuery element


# Returns jquery: query result.
# Use this function in performance-wise reasons,
# line accessing DOM in loops, etc.
# -> string id: target element id.
byId = (id) -> jQuery document.getElementById id


###
    Convert html data attributes to the object.
    Example:
        => dataToOpts "mymodule", <div data-mymodule-name="Andrew"
                                       data-mymodule-phone="100500"></div>
        {name: "Andrew", phone: "100500"}
###
dataToOpts = (sufx, node) ->
    jnode = jQuery node
    return {} unless bool jnode.data()

    _keys = keys jnode.data()
    toHash (_keys.filter((key) -> key[0...sufx.length] is sufx)
                .map((key) -> [key[sufx.length...], (jnode.data key)]))

isTextarea = (jnode) -> jnode.is "textarea"


# Does give element is disabled?
# -> IDom target: element.
# Returns bool: designator.
isDisabled = (target) ->
    (el = jwrap target).is(":disabled") or el.hasClass "h-disabled"


isVisible = (jnode) -> jnode.is ":visible"

isReadonly = (jnode) -> jnode.prop "readonly"

readonly = (jnode) ->
    el = jwrap jnode

    # Is this a `select`?
    if el.is "select"
        return el
            .mousedown ignoreSelectClick
            .addClass "h-disabled"

    el.prop "readonly", true


writable = (jnode) -> jnode.prop "readonly", false


# This elements can be disabled by 'disabled' attribute.
html_disableable = ["INPUT", "BUTTON", "SELECT", "TEXTAREA"]

ignoreSelectClick = (ev) ->
    ev.preventDefault()
    this.blur()
    window.focus()

# Disables given element.
# -> jquery|dom target: element to disabled.
#    DO NOT PASS jQuery QUERYSET OF ELEMENTS HERE,
#    since function is intented to use with single element only
#    and will not work correctly if there is a mix of elements.
#    Eg.: if we have a mix of `input`, `select`, and `textarea`,
#    so `select` disabling logic will be applied to `input` and `textarea`,
#    which will cause of incorrect behaviour of this elements.
# Returns jquery: DOM element.
disable = (target) ->
    el = jwrap target

    # Is this a `select`?
    if el.is "select"
        return el
            .mousedown ignoreSelectClick
            .addClass "h-disabled"

    # Adding standard disability attribute,
    # which makes browser to ignore events for target element.
    el.prop "disabled", true

    # Since some elements aren't disabled by 'disabled' attribute,
    # we should additionally mark element as disabled.
    tag = el.prop "tagName"
    el.addClass "h-disabled" if tag not in html_disableable

    # Returning reference to wrapped element.
    el


# See disable() for more info.
enable = (target) ->
    el = jwrap target

    # Is this a `select`?
    if el.is "select"
        return el
            .off "mousedown", ignoreSelectClick
            .removeClass "h-disabled"

    # Removing all possible disability attrs.
    el.prop "disabled", false
    el.removeClass "h-disabled"

    # Returning reference to wrapped element.
    el


check = (checkbox) ->
    checkbox.prop "checked", true
            .prop "indeterminate", false

uncheck = (checkbox) ->
    checkbox.prop "checked", false
            .prop "indeterminate", false

isChecked = (checkbox) ->
    checkbox.is ":checked"

# Returns checkbox related to given label,
# -> IDom label: label.
# Returns IDom: checkbox element.
findCheckbox = (label) ->
    jQuery "##{label.attr 'for'}"

indeterminate = (checkbox) -> checkbox.prop "indeterminate", true

getSelected = (select) ->
    option = select.find("option").filter ":checked"
    {option, value: option.val(), title: option.text()}

selectOption = (select, value) ->
    select.find("option[value=#{value}]").prop "selected", true

# Focus on the first editable input.
# -> IContainer container: container where lookup for input.
# Returns DOM: focused input.
focus = (container) ->
    # Input to focus.
    target = container
        .find "input,textarea,select"
        .not "[readonly]"
        .not "[disabled]"
        .not ":hidden"
        .first()

    # Used to improve UX.
    return (
        if isMobile() then target
        else target.focus()
    )

# Selects text in given input.
# Useful with focus() function.
# -> DOM input: target input.
mark = (input) -> input.select()

hasScrollBar = (jnode) -> jnode.get(0).scrollHeight > jnode.outerHeight()

lockClickable = (input, cb) ->
    if isDisabled input
        return false

    disable input
    cb()

isPreventSubmitOnEnter = (e) ->
    eventCharCode = e.which or e.keyCode
    eventCharCode is KEY.ENTER and not e.shiftKey

blockEnter = ->
    (jQuery document.body).on "keydown", (e) ->
        if e.target.type == "textarea" and isPreventSubmitOnEnter(e)
            e.stopPropagation()
            e.preventDefault()

updateElements = requestAnimationFrame

# Creates new context for given jQuery element.
# -> DomElement* element: jQuery element.
# Returns jQuery: same element, but with new jQuery context.
jctx = (element) -> jQuery element[0]


isHiddenTab = ->
    any [ document.hidden
        , document.mozHidden
        , document.webkitHidden
        , document.msHidden ]


# Prevents multi-clicking on element.
# -> object element: target element.
# Returns bool: is anti-clicking enabled?
preventMulticlick = (element) ->
    # Doing nothing if element is marked as disabled.
    return true if isDisabled target = jQuery element

    # Disabling target element
    # with re-enabling it after some period of time.
    disable target
    setTimeout (-> enable target), 1000
    false

getFirstScrollParent = (element, includeHidden) ->
    style = getComputedStyle(element)
    excludeStaticParent = style.position == 'absolute'
    overflowRegex = if includeHidden then /(auto|scroll|hidden)/ else /(auto|scroll)/
    if style.position == 'fixed'
        return null
    parent = element
    while parent = parent.parentElement
        style = getComputedStyle(parent)
        if excludeStaticParent and style.position == 'static'
            continue
        if overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
            return parent
    null


# Returns Observer: `before change` event handler.
# -> jquery select: target select element.
# -> void handher: external handler.
# Note, that returned Observer must be disposed
# to avoid memory leaks (see RxJS observers.)
onBeforeChange = (select, handler) ->
    fromEvent select, "focus"
        # When focused,

        .switchMap (ev) ->
            # saving current state of `select`,
            # and returning `select` change observable.
            prev = (target = jQuery ev.target).val()

            fromEvent select, "change"
                .switchMap -> [{
                    target
                    prev
                    next: target.val()
                }]

        .subscribe ({target, prev, next}) ->
            # When changed, setting previous value back
            # and calling handler, with passing callback,
            # which must be called by external code,
            # if one wants to set `next` value.
            target.val prev
            handler -> target.val next


# Reference to document body.
body = jQuery document.body


module.exports = {
    dataToOpts, isReadonly, readonly, writable, disable, enable, check, uncheck,
    isTextarea, isDisabled, indeterminate, lockClickable, focus,
    updateElements, jctx, isVisible, getSelected, selectOption, mark, isChecked,
    isHiddenTab, body, findCheckbox, preventMulticlick, getFirstScrollParent, byId,
    onBeforeChange, blockEnter, isPreventSubmitOnEnter
}
