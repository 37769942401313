import { forwardRef, memo } from 'react';
import classnames from 'classnames';

import { ImageProps } from '@@types/ui';

import * as styles from './Image.module.scss';

// eslint-disable-next-line prefer-arrow-callback
const ImageWithRef = forwardRef<SVGSVGElement, ImageProps>(
  function Image(props, ref): JSX.Element | null {
    const {
      image,
      className,
      fill,
      dataCid = null,
      position,
      size,
      cursor = 'default',
      ...svgProps
    } = props;

    const Component = image;

    if (!Component) {
      return null;
    }

    const imageCls = classnames(styles.image, className, {
      [styles[`image_size_${size}`]]: size,
      [styles[`image_fill_${fill}`]]: fill,
      [styles[`image_position_${position}`]]: position,
      [styles[`image_cursor_${cursor}`]]: cursor,
    });

    return (
      <Component
        ref={ref}
        className={imageCls}
        data-cid={dataCid}
        {...svgProps}
      />
    );
  },
);

export default memo(ImageWithRef);
