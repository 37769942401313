/* eslint-disable no-param-reassign */
import toast from 'react-hot-toast';
import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import axios, { HttpStatusCode } from 'axios';
import i18next from 'i18next';
import _concat from 'lodash/concat';
import _flatten from 'lodash/flatten';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _omitBy from 'lodash/omitBy';
import _pickBy from 'lodash/pickBy';
import _values from 'lodash/values';

import {
  DEFAULT_BACKOFF_TIME,
  DEFAULT_RETRIES_COUNT,
  JITTER_DELAY_BOUNDARIES,
  NOTIFY_PREFIX_REGEXP,
  RETRY_STATUS_CODES,
} from '@@constants/transport';
import { sleep } from '@@helpers/common/sleep';
import { getRefreshToken, setTokens } from '@@helpers/transport/tokens';
import cookieService from '@@services/cookie';

import type {
  ErrorMessagesDictionary,
  ServerErrorResponseData,
} from '@@types/api';

import { doLogoutRoutine, mutex } from '../shared/common';

export const appInstance = axios.create({
  baseURL: '/app',
  withCredentials: true,
  retry: DEFAULT_RETRIES_COUNT,
  retryDelay: DEFAULT_BACKOFF_TIME,
  headers: {
    'X-Revision': process.env.REVISION,
    'X-Version': process.env.VERSION,
  },
});

export const chatServiceInstance = axios.create({
  baseURL: process.env.CHATS_API_BASE_URL,
  withCredentials: true,
  retry: DEFAULT_RETRIES_COUNT,
  retryDelay: DEFAULT_BACKOFF_TIME,
});

export const webhookServiceInstance = axios.create({
  baseURL: process.env.WEBHOOKS_API_BASE_URL,
  withCredentials: true,
  retry: DEFAULT_RETRIES_COUNT,
  retryDelay: DEFAULT_BACKOFF_TIME,
});

const errorStatusChecker = (
  error: AxiosError<ServerErrorResponseData, unknown>,
) => {
  if (!error.response) {
    return;
  }

  const {
    response: {
      status,
      data: { revision, redirect, printable, message },
    },
  } = error;

  if (revision) {
    window.location.reload();
    return;
  }

  if (redirect) {
    if (redirect === '__reload__') {
      window.location.reload();
    } else {
      window.location.replace(redirect + window.location.search);
    }

    return;
  }

  if (message) {
    if (printable && _isString(message)) {
      toast.error(message);
      return;
    }

    if (_isObject(message)) {
      const notifiersFromMessage = _pickBy(
        message as { [key: string]: string[] },
        (_, key) => {
          return NOTIFY_PREFIX_REGEXP.test(key);
        },
      );
      const notifiersFromValidation = _pickBy(
        message.validation as ErrorMessagesDictionary,
        (_, key) => {
          return NOTIFY_PREFIX_REGEXP.test(key);
        },
      );

      // temporarily until translations appear in services
      const SERVICES_ERRORS_MAP: Record<string, string> = {
        'Client can have only 10 conversations': i18next.t(
          'Client can have only 10 conversations',
        ),
        'Max allowed webhook amount reached!': i18next.t(
          'Max allowed webhook amount reached!',
        ),
      };

      _concat(
        _flatten(_values(notifiersFromMessage)),
        _flatten(_values(notifiersFromValidation)),
      ).forEach((notify) => {
        toast.error(SERVICES_ERRORS_MAP[notify] || notify);
      });

      return;
    }
  }

  if (status >= HttpStatusCode.InternalServerError) {
    toast.error(i18next.t('Error occurred. Please contact technical support'));
    return;
  }

  if (status === HttpStatusCode.TooManyRequests) {
    toast.error(
      i18next.t(
        'Your account has been temporarily locked due to many requests. Please try again later',
      ),
    );
    return;
  }

  if (status === HttpStatusCode.MethodNotAllowed) {
    toast.error(i18next.t('Invalid request type. Contact technical support'));
    return;
  }

  if (status === HttpStatusCode.Forbidden) {
    toast.error(i18next.t('You have no access to this resource'));
    return;
  }

  if (
    status === HttpStatusCode.Unauthorized ||
    status === HttpStatusCode.Conflict
  ) {
    window.location.reload();
    return;
  }

  if (status >= HttpStatusCode.BadRequest) {
    if (_isNil(message)) {
      toast.error(
        i18next.t('Resource not found. Please contact technical support'),
      );
    }

    return;
  }

  if (status >= HttpStatusCode.MultipleChoices) {
    if (_isString(message)) {
      window.location.replace(message);
    }

    return;
  }

  toast.error(i18next.t('Something went wrong. Please try again later'));
};

const exponentialBackoff = async (config: AxiosRequestConfig) => {
  config.retry = config.retry ?? 0;
  config.retry -= 1;

  const [minDelay, maxDelay] = JITTER_DELAY_BOUNDARIES;

  const min = Math.ceil(minDelay);
  const max = Math.floor(maxDelay);

  // Jitter will result in a random value between `minDelay` and `maxDelay`.
  // Prevents users retry at the same time.
  // See https://www.awsarchitectureblog.com/2015/03/backoff.html
  const jitterTime = Math.floor(Math.random() * (max - min + 1)) + min;

  if (config.retryDelay) {
    await sleep(config.retryDelay + jitterTime);
  }
};

export const updateToken = async () => {
  const response = await appInstance.post(
    '/token/refresh',
    { refresh_token: getRefreshToken() },
    { extraConfig: { isUnlocked: true } },
  );

  if (!response.data.isAuthenticated) {
    doLogoutRoutine(response.data);
  }

  return response;
};

const getResponseRejectInterceptor =
  (instance: AxiosInstance) =>
  async (error: AxiosError<ServerErrorResponseData, unknown>) => {
    if (!error.response || !error.config) {
      return Promise.reject(error);
    }

    const {
      config,
      response: { status },
    } = error;

    if (status === HttpStatusCode.Unauthorized) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          const authResponse = await updateToken();

          if (!authResponse.data.isAuthenticated) {
            return Promise.reject(error);
          }

          return instance(config);
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();

        return instance(config);
      }
    }

    if (RETRY_STATUS_CODES.includes(status)) {
      if (!config || !config.retry || config.retry < 0) {
        errorStatusChecker(error);

        return Promise.reject(error);
      }

      await exponentialBackoff(config);

      return instance(config);
    }

    errorStatusChecker(error);

    return Promise.reject(error);
  };

appInstance.interceptors.request.use(
  async (config) => {
    const { isUnlocked, withNull } = config.extraConfig || {};

    if (!isUnlocked) {
      await mutex.waitForUnlock();
    }

    if (!withNull) {
      // Backwards compatible with legacy endpoints
      config.data = _omitBy(config.data, _isNil);
    }

    config.headers['X-CSRFToken'] = cookieService.get('csrftoken');

    // Merge extra headers with default headers
    // Mutates the original object
    Object.assign(config.headers, config.extraHeaders);

    return config;
  },
  (error: AxiosError<ServerErrorResponseData, unknown>) =>
    Promise.reject(error),
);

appInstance.interceptors.response.use((response) => {
  setTokens(response.data);

  return response;
}, getResponseRejectInterceptor(appInstance));

chatServiceInstance.interceptors.request.use(
  async (config) => {
    const { isUnlocked } = config.extraConfig || {};

    if (!isUnlocked) {
      await mutex.waitForUnlock();
    }

    // Merge extra headers with default headers
    // Mutates the original object
    Object.assign(config.headers, config.extraHeaders);

    return config;
  },
  (error: AxiosError<ServerErrorResponseData, unknown>) =>
    Promise.reject(error),
);

chatServiceInstance.interceptors.response.use(
  (response) => response,
  getResponseRejectInterceptor(chatServiceInstance),
);

webhookServiceInstance.interceptors.request.use(
  async (config) => {
    const { isUnlocked } = config.extraConfig || {};

    if (!isUnlocked) {
      await mutex.waitForUnlock();
    }

    // Merge extra headers with default headers
    // Mutates the original object
    Object.assign(config.headers, config.extraHeaders);

    return config;
  },
  (error: AxiosError<ServerErrorResponseData, unknown>) =>
    Promise.reject(error),
);

webhookServiceInstance.interceptors.response.use(
  (response) => response,
  getResponseRejectInterceptor(chatServiceInstance),
);
