import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _setWith from 'lodash/setWith';

import { syncWithPersistedFields } from '@@helpers/table';
import { getBaseFletcherTableName } from '@@helpers/tables/getBaseFletcherTableName';
import { fetchRequest } from '@@services/transport/fetch';

import { findRowData, getPath, getPayloadData } from './utils';

export const fetchTableData = createAsyncThunk(
  'tables/fetchTableData',
  async (
    {
      name,
      queries,
      requestUrl,
      requestMethod,
      extraCbs,
      containerId,
      isStaff,
      isChatService,
      isWebhookService,
      rpc,
      data,
    },
    { getState, rejectWithValue },
  ) => {
    const response = await (requestUrl || rpc
      ? fetchRequest({
          url: requestUrl,
          method: requestMethod || 'GET',
          payload: queries,
          isStaff,
          isChatService,
          isWebhookService,
          containerId: containerId || name,
        })
      : new Promise((resolve) => {
          resolve({ data });
        }));

    const responseWithBranchId = {
      ...response,
      branchId: getState().branches.activeId,
    };

    if (response.message) {
      return rejectWithValue(response.message);
    }

    if (extraCbs && extraCbs.length) {
      return extraCbs.reduce((acc, next) => next(acc), responseWithBranchId);
    }

    return responseWithBranchId;
  },
);

const tablesSlice = createSlice({
  name: 'tables',
  initialState: {},
  reducers: {
    initFields(state, { payload }) {
      const { name, keys, isNeedUpdateBaseFletcherTable } = payload;

      const path = getPath({ name, relativeValue: keys.relativeValue });

      const persistedFields = state[name]?.persistConfig?.fields;

      _setWith(
        state,
        path,
        {
          ..._get(state, path),
          ..._omit(keys, [
            !!keys.relativeValue && 'relativeValue',
            !!keys.persist && 'persist',
          ]),
          ...(keys.persist && {
            fields: syncWithPersistedFields(keys.fields, persistedFields),
          }),
          error: null,
        },
        Object,
      );

      if (isNeedUpdateBaseFletcherTable) {
        _setWith(
          state,
          getBaseFletcherTableName(path),
          { sort: keys.sort },
          Object,
        );
      }
    },
    removeTable(state, { payload }) {
      delete state[payload.name];
    },
    changeFields(state, { payload }) {
      const { id, tableName } = payload;

      const filterPosition = _findIndex(
        state[tableName].fields,
        (item) => item.field === id,
      );

      const path = [tableName, 'fields', filterPosition, 'checked'];
      const checked = _get(state, path);

      _set(state, path, !checked);
    },
    updatePosFields(state, { payload }) {
      const { updatedFields, tableName } = payload;

      _set(state, [tableName, 'fields'], updatedFields);
    },
    changeWidthField(state, { payload }) {
      const {
        column,
        width,
        changedFields,
        relativeValue,
        tableName,
        isNeedUpdateBaseFletcherTable,
      } = payload;

      const newFields = relativeValue
        ? [...state[tableName][relativeValue].fields]
        : [...state[tableName].fields];
      const fieldIndex =
        typeof column === 'number' ? column : _findIndex(newFields, column);

      newFields.splice(fieldIndex, 1, { ...newFields[fieldIndex], width });

      if (relativeValue) {
        const nestedTables = _omit(state[tableName], [
          'changedFields',
          'error',
          'persistConfig',
        ]);

        const preparedTables = _reduce(
          nestedTables,
          (res, table, tableId) => {
            return { ...res, [tableId]: { ...table, fields: newFields } };
          },
          {},
        );

        return {
          ...state,
          [tableName]: {
            ...state[tableName],
            ...preparedTables,
            persistConfig: { fields: newFields },
          },
        };
      }

      return {
        ...state,
        [tableName]: {
          ...state[tableName],
          changedFields,
          fields: newFields,
        },
        ...(isNeedUpdateBaseFletcherTable && {
          [getBaseFletcherTableName(tableName)]: {
            ...state[tableName],
            changedFields,
            fields: newFields,
          },
        }),
      };
    },
    changeSorting(state, { payload }) {
      const { tableName, relativeValue, sort, loading } = payload;

      const path = getPath({ name: tableName, relativeValue });

      _set(state, `${path}.sort`, sort);
      _set(state, `${path}.loading`, loading);
    },
    onPageChange(state, { payload }) {
      const { tableName, offset, page, relativeValue } = payload;

      if (relativeValue) {
        _set(state, [tableName, relativeValue, 'offset'], offset);
        _set(state, [tableName, relativeValue, 'page'], page);
      } else {
        _set(state, [tableName, 'offset'], offset);
        _set(state, [tableName, 'page'], page);
      }
    },
    tableAddData(state, { payload }) {
      const { tableName, data } = payload;

      const updatedData = [
        ..._get(state, [tableName, 'data'], []),
        ...getPayloadData(data),
      ];

      _set(state, [tableName, 'data'], updatedData);
      _set(state, [tableName, 'count'], updatedData.length);
    },
    tablePutData(state, { payload }) {
      const { tableName, data } = payload;

      const updatedData = getPayloadData(data);

      _set(state, [tableName, 'data'], updatedData);
      _set(state, [tableName, 'count'], updatedData.length);
    },
    tableResetData(state, { payload }) {
      const { tableName, nested } = payload;

      if (nested) {
        _set(state, tableName, {
          persistConfig: _get(state, [tableName, 'persistConfig']),
        });
      } else {
        _set(state, [tableName, 'data'], []);
        _set(state, [tableName, 'count'], 0);
      }
    },
    tableRemoveData(state, { payload }) {
      const { tableName, findConfig } = payload;

      if (state[tableName] && state[tableName].data && findConfig) {
        const updatedData = state[tableName].data.filter(
          (row) => !findRowData(row, findConfig),
        );

        _set(state, [tableName, 'data'], updatedData);
        _set(state, [tableName, 'count'], updatedData.length);
      }
    },
    tableReplaceData(state, { payload }) {
      const { tableName, findConfig, replaceValue } = payload;

      if (
        state[tableName] &&
        state[tableName].data &&
        findConfig &&
        replaceValue
      ) {
        state[tableName].data = state[tableName].data.map((row) => {
          return findRowData(row, findConfig, replaceValue);
        });
      }
    },
    setSelectedTableRowId(state, { payload }) {
      const { tableName, selectedRowId, selectedCellId } = payload;

      _set(state, [tableName, 'selectedRowId'], selectedRowId);
      _set(state, [tableName, 'selectedCellId'], selectedCellId, null);
    },
    externalReloadTable(state, { payload }) {
      const { tableName, shouldUpdateTable, isResetPage = true } = payload;

      _set(state, tableName, {
        ..._get(state, tableName),
        shouldUpdateTable,
        isResetPage,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTableData.pending, (state, { meta }) => {
      const path = getPath(meta.arg);

      _set(state, `${path}.loading`, true);
      _set(state, `${path}.error`, null);
    });

    builder.addCase(fetchTableData.fulfilled, (state, { payload, meta }) => {
      const path = getPath(meta.arg);

      _set(state, path, {
        ..._get(state, path),
        ...payload,
        loading: false,
      });
    });

    builder.addCase(fetchTableData.rejected, (state, { payload, meta }) => {
      const path = getPath(meta.arg);

      _set(state, `${path}.loading`, false);
      _set(state, `${path}.error`, payload);
    });
  },
});

export const {
  initFields,
  removeTable,
  changeFields,
  updatePosFields,
  changeWidthField,
  changeSorting,
  onPageChange,
  tableAddData,
  tablePutData,
  tableResetData,
  tableRemoveData,
  tableReplaceData,
  setSelectedTableRowId,
  externalReloadTable,
} = tablesSlice.actions;

export default tablesSlice.reducer;
