// extracted by mini-css-extract-plugin
var _1 = "aqL8R";
var _2 = "XbwbI";
var _3 = "RIUV6";
var _4 = "Rz73Y";
var _5 = "tdQkk";
var _6 = "gXZ0x";
var _7 = "YlDxU";
var _8 = "pRlt7";
var _9 = "isttm";
var _a = "HiffK";
export { _1 as "defaultSpinner", _2 as "defaultSpinner_color_blue", _3 as "defaultSpinner_color_orange", _4 as "defaultSpinner_color_white", _5 as "defaultSpinner_size_big", _6 as "defaultSpinner_size_medium", _7 as "defaultSpinner_size_small", _8 as "defaultSpinner_withoutMargin", _9 as "rotation", _a as "spinnerWrapper" }
