import { createApi } from '@reduxjs/toolkit/query/react';
import { RawAxiosRequestHeaders } from 'axios';

import { webhookServiceInstance } from '@@services/transport/axios';

import type { AxiosBaseQuery, BaseQueryError } from '@@types/api';

const axiosBaseQuery = (): AxiosBaseQuery => async (axiosConfig) => {
  try {
    const { ID } = require('interface/Company');

    const extraHeaders: RawAxiosRequestHeaders = {
      'X-Company-id': ID,
    };

    // eslint-disable-next-line no-param-reassign
    axiosConfig.extraHeaders = extraHeaders;

    const { data, ...meta } = await webhookServiceInstance(axiosConfig);

    return { data, meta };
  } catch (axiosError) {
    return {
      error: axiosError as BaseQueryError,
    };
  }
};

const api = createApi({
  reducerPath: 'webhookService/api',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: () => ({}),
});

export default api;
